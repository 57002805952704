import { React, useEffect, useState } from 'react';
import axios from 'axios';

import {
    localStorageStore,
    fetchUtils,
    Layout,
    Admin,
    Resource,
    ListGuesser,
} from 'react-admin';

import { generateFingerprint } from 'utils';

import { dataProvider, HTTPSetter } from 'admin/dataProvider';
import authProvider from 'admin/authProvider';
import UserIcon from 'svg/users-icon';

import { defaultTheme } from 'react-admin';
import indigo from '@mui/material/colors/indigo';
import pink from '@mui/material/colors/pink';
import red from '@mui/material/colors/red';

import Header from 'components/Header';
import {UserList, UserShow, UserCreate} from 'resources/users';
import {LicenseList, LicenseShow, LicenseCreate} from 'resources/licenses';
import { LicenseCodeBundleList, LicenseCodeBundleCreate, LicenseCodeBundleShow } from 'resources/licenseCodeBundles';
import {LicenseCodeList} from 'resources/licenseCodes';
import {LicenseCodeDirectSaleList} from 'resources/licenseCodeDirectSales';
import { EDUStudentTransactionList, EDUStudentTransactionCreate } from 'resources/eduStudentTransaction';



//SET THE BASE URL
axios.defaults.baseURL = process.env.REACT_APP_HOST_URL;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.REACT_APP_HOST_URL;

//MANAGE CORS
if (process.env.NODE_ENV === "development") {
    axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
    axios.defaults.headers.common['Bypass-Tunnel-Reminder'] = true;
    //REACT_APP_HOST_URL is the server url
    //TO RUN TESTS ON MOBILE DEVICE VIA localtunnel: REACT_APP_HOST_URL=ttps://cowardly-bird-11.loca.lt npm run start
}

//DISABLE CONSOLE LOG/ETC
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

//DEFAULT HTTP RESPONSE HANDLER
axios.interceptors.response.use((response) => {
  //STATUS 2xx
  return response;
}, (err) => {

  if (!err.response) {  //network error - service unreachable
    // authProvider.logout();
  }
  else if (err.response.status === 401) { //401 unauthorized
    // authProvider.logout();
  }
  else if (err.response.status === 400) { //400 bad-request
    console.log('ERROR 400!', err.response.data.error);
  }
  return Promise.reject(err);
});


//react-admin THEME SETUP
const admin_theme = {
    ...defaultTheme,
    palette: {
        primary: indigo,
        secondary: pink,
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },
}


//DEFINE LAYOUT COMPONENT
const admin_layout = (props) => <Layout {...props} appBar={Header} />;


const store = localStorageStore();
function App(props) {

    //AUTH TOKEN FINGERPRINTING
    useEffect(()=>{
        generateFingerprint();
    }, []);

    return (
        <Admin 
            layout={admin_layout}
            theme={admin_theme} 
            dataProvider={dataProvider}
            authProvider={authProvider}
            store={store}
        >
        <Resource
            name="users"
            icon={UserIcon}
            list={UserList}
            show={UserShow}
            create={UserCreate}
        />
        <Resource
            name="licenses"
            list={LicenseList}
            create={LicenseCreate}
        />
        <Resource
            name="license-code-bundles"
            list={LicenseCodeBundleList}
            create={LicenseCodeBundleCreate}
            show={LicenseCodeBundleShow}
            recordRepresentation={(record) => `${record.purchaser}: ${record.description}`}
        />
        <Resource
            name="license-codes"
            list={LicenseCodeList}
        />
         <Resource
            name="license-code-direct-sales"
            list={LicenseCodeDirectSaleList}
        />
        <Resource
            name="edu-student-transactions"
            list={EDUStudentTransactionList}
            create={EDUStudentTransactionCreate}
        />
        </Admin>
    );
};

export default App;
