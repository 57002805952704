import { List, Show, Edit, FilterButton, useNotify, Create, required, ExportButton, Title, email } from 'react-admin'
import { Datagrid, FormDataConsumer, DateField, DateInput, TextField, ReferenceField, AutocompleteInput, NumberField, NumberInput, ReferenceInput, SelectField, SelectInput, PasswordInput, BooleanField, EmailField, NullableBooleanInput, SimpleShowLayout, TopToolbar, ListButton, ShowButton, SimpleForm, TextInput} from 'react-admin';
import licenseTypes from './licenseTypes';


const EDUStudentTransactionListActions = () => (
    <TopToolbar>
    </TopToolbar>
);

const EDUStudentTransactionFilters = [
    <TextInput label="Txn Id" source="transactionId" defaultValue="" alwaysOn/>
];

export const EDUStudentTransactionList = () => (
    <List filters={EDUStudentTransactionFilters}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="id"/>
            <TextField source="email"/>
            <TextField source="contactName"/>
            <TextField label="Txn Id" source="transactionId"/>
            <TextField source="licenseCode"/>
        </Datagrid>
    </List>
);


export const EDUStudentTransactionCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="email" label="Email" validate={[required(), email()]} fullWidth  />
            <TextInput source="contactName" label="Contact Name" validate={[required()]} fullWidth />
            <TextInput source="ticket" label="Zendesk Ticket" />
        </SimpleForm>
    </Create>
);